import React from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
//import logo from './logo.svg';
import './App.css';
import './styles.css';
import EncryptDecrypt from "./components/EncryptDecrypt";
import Decrypt from "./components/Decrypt";
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';

const items = [

  { name: 'encryptdecrypt', label: 'Encrypt and Decrypt' },
  { name: 'decrypt', label: 'Decrypt' },
]
  
function App() {
  return (
    <div>
      <div>
        <ul>
          {items.length > 0 && items.map((list) => {
            return <li>
              <Link to={list.name} className="active nav-link"><i className="fas fa-home">{list.label}</i></Link>
              {list.items && list.items.map((sublist) => {
                return <ul>
                  <li>
                    <a href="#"><i className="ion-ios-color-filter-outline"></i>{sublist.label}</a>
                    {sublist.items && sublist.items.map((sublistlist) => {
                      return <ul>
                        <li>
                          <a href="#"><i className="ion-ios-color-filter-outline"></i>{sublistlist.label}</a>
                        </li>
                      </ul>


                    })}
                  </li>
                </ul>

              })}


            </li>
          })}


        </ul>
      </div>

      <div className="container mt-3">
        <Routes>
          <Route path='' element={<EncryptDecrypt />} />
          <Route path='/encryptdecrypt' element={<EncryptDecrypt />} />
          <Route path='/decrypt' element={<Decrypt />} />
        </Routes>
      </div>

    </div>
  )
}

export default App;
