import { RETRIEVE_TUTORIALS, DELETE_ALL_TUTORIALS, CREATE_TUTORIALS, DELETE_TUTORIALS, UPDATE_TUTORIALS } from "../actions/types";
const initialState = [];

function tutorialReducer(tutorials = initialState, action){
    const { type, payload } = action;
    switch(type) {
        case CREATE_TUTORIALS:
            return [...tutorials, payload];
        case RETRIEVE_TUTORIALS:
            return payload;
        case UPDATE_TUTORIALS:
            return tutorials.map((tutorial) => {
                if (tutorial.id === payload.id) {
                    return {
                        ...tutorial,
                        ...payload,
                    };
                } else {
                    return tutorial;
                }
            });
        case DELETE_TUTORIALS:
            return tutorials.filter(({ id }) => id !== payload.id);       
        case DELETE_ALL_TUTORIALS:
            return [];
        
        default:
            return tutorials;
    }
};
export default tutorialReducer;
