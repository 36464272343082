import React, { useState } from "react";
import CryptoJS from 'crypto-js';
import atob from 'atob';
const Decrypt = () => {
  const [keys, setKey] = useState({
    formkeys: '',
    finalkey: '',
    encryptData: '',
    decryptData: ''
  });
  const [resultData, setResultData] = useState({
    resultValue: ''
  })
  const [showresult, setshowresult] = useState(false);

  const decrypt = () => {
    setshowresult(true)

    const fkey = CryptoJS.enc.Utf8.parse(atob(keys.formkeys));
    const fiv = CryptoJS.enc.Base64.parse('rpH384l8rT02vnH4rpH3');
    let finalString;
    if (resultData.resultValue) {
      finalString = resultData.resultValue;

    } else {

      finalString = keys.finalkey
    }
    const option = {
      iv: fiv,
      mode: CryptoJS.mode.CTR,
      padding: CryptoJS.pad.NoPadding
    };
    const cipherObj = CryptoJS.lib.CipherParams.create({
      ciphertext: CryptoJS.enc.Base64.parse(finalString)
    });
    const decrypt = CryptoJS.AES.decrypt(cipherObj, fkey, option);
    const finalDecrypt = decrypt.toString(CryptoJS.enc.Utf8);
    setKey({ ...keys, decryptData: finalDecrypt })

  }
  return (
    <div className="text-center border border-light p-3">
      <p className="h4 mb-4">Decrypt Data</p>
      <div className="row">
        <div className="form-group col-lg-6">
          <input type="text" placeholder='Key' className="form-control mb-4" onChange={(e) => { setKey({ ...keys, formkeys: e.target.value }) }} />
        </div>
      </div>

      {
        showresult ?
          <div className="row">
            <div className="form-group col-lg-6">
              <textarea cols="56" rows="5" value={keys.decryptData} onChange={(e) => { setKey({ ...keys, decryptData: e.target.value }) }}></textarea>
            </div>
          </div> : null
      }

      <div className="row">
        <div className="form-group col-lg-6">
          <textarea cols="56" rows="5" onChange={(e) => { setKey({ ...keys, finalkey: e.target.value }) }} ></textarea>
          <button  className="btn btn-outline-success btn-block my-4" onClick={decrypt}>Decrypt</button>
        </div>
      </div>
    </div>
  )
};

export default Decrypt;

