import React, { useState } from "react";
import CryptoJS from 'crypto-js';


const EncryptDecrypt = () => {
  const [keys, setKey] = useState({
    formkeys: '',
    finalkey: '',
    encryptData: '',
    decryptData: ''
  });
  const [resultData, setResultData] = useState({
    resultValue: ''
  })


  const [showresult, setshowresult] = useState(false)


  const encrypt = () => {
    const data = keys.encryptData;
    const key = keys.formkeys; //7v9y/B?E(H+MbQeThHmZq4t7w!z%C&F) atob
    const fkey = CryptoJS.enc.Utf8.parse(key);
    const fiv = CryptoJS.enc.Base64.parse('rpH384l8rT02vnH4rpH3');
    const enc = CryptoJS.AES.encrypt(JSON.stringify(data), fkey, {
      iv: fiv,
      mode: CryptoJS.mode.CTR,
      padding: CryptoJS.pad.NoPadding,
    });
    const final = enc.ciphertext.toString(CryptoJS.enc.Base64);
    const finalString = final.toString(CryptoJS.enc.Base64);
    setResultData({
      ...resultData, resultValue: finalString
    })
  }

  const decrypt = () => {
    setshowresult(true)

    const fkey = CryptoJS.enc.Utf8.parse(keys.formkeys);
    const fiv = CryptoJS.enc.Base64.parse('rpH384l8rT02vnH4rpH3');
    let finalString;
    if (resultData.resultValue) {
      finalString = resultData.resultValue;

    } else {

      finalString = keys.finalkey
    }

    console.log(finalString);
    const option = {
      iv: fiv,
      mode: CryptoJS.mode.CTR,
      padding: CryptoJS.pad.NoPadding
    };
    const cipherObj = CryptoJS.lib.CipherParams.create({
      ciphertext: CryptoJS.enc.Base64.parse(finalString)
    });
    console.log(cipherObj);
    const decrypt = CryptoJS.AES.decrypt(cipherObj, fkey, option);
    const finalDecrypt = decrypt.toString(CryptoJS.enc.Utf8);
    setKey({ ...keys, decryptData: JSON.parse(finalDecrypt) })

  }
  return (
    <div className="text-center border border-light p-3">
      <p className="h4 mb-4">Encrypt Data</p>
      <div className="row">
        <div className="form-group col-lg-6">
          <input type="text" placeholder='Enter Key' className="form-control mb-4"
            onChange={(e) => { setKey({ ...keys, formkeys: e.target.value }) }} />
        </div>
      </div>
      <div className="row">
        <div className="form-group col-lg-4">
          <textarea cols="56" rows="5" placeholder="Enter EncryptData" onChange={(e) => { setKey({ ...keys, encryptData: e.target.value }) }}></textarea>
          <button className="btn btn-outline-primary btn-block my-4" onClick={encrypt}>Encrypt</button>
        </div>
      </div>
      {
        showresult ?
          <div className="row">
            <div className="form-group col-lg-4">
              <textarea cols="56" rows="5" placeholder="Enter DecryptData" value={keys.decryptData} onChange={(e) => { setKey({ ...keys, decryptData: e.target.value }) }}></textarea>
            </div>
          </div> : null
      }
      <div className="row">
        <div className="form-group col-lg-4">
          <textarea cols="56" rows="5" placeholder="ResultData" value={resultData.resultValue}>{resultData.resultValue}</textarea>
          <button className="btn btn-outline-success btn-block my-4" onClick={decrypt}>Decrypt</button>
        </div>
      </div>
    </div>
  )
};

export default EncryptDecrypt;

